import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, throwError } from 'rxjs';

import { Http, Response, Headers, URLSearchParams, RequestOptions} from '@angular/http';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { map, take, catchError} from 'rxjs/operators';
import { AuthService } from 'app/auth.service';
import { FclService } from 'app/main/apps/service/fcl.service';
import { VoyageService } from 'app/main/apps/service/voyage.service';
import { VesselService } from 'app/main/apps/service/vessel.service';
import { SupplierService } from 'app/main/apps/service/supplier.service';
import { SeaportService } from 'app/main/apps/service/seaport.service';
import { SeaTerminalService } from 'app/main/apps/service/sea-terminal.service';

@Injectable()
export class VoyageListService implements Resolve<any>
{
    voyageList: any[];
    onVoyageListChanged: BehaviorSubject<any>;
    dataCreate;
    dataUpdate;
    dataDelete;
    
    vessel: any[];
    supplier: any[];
    seaport: any[];
    seaTerminal: any[];
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        private _http: Http,
        private authService: AuthService,
        private vesselService: VesselService,
        private supplierService: SupplierService,
        private seaportService: SeaportService,
        private seaTerminalService: SeaTerminalService,
        private voyageService: VoyageService,
        private fclService: FclService,
        private router: Router
    )
    {
        // Set the defaults
        this.onVoyageListChanged = new BehaviorSubject({});
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getVoyageList(),
                this.vesselService.getVessel(),
                this.voyageService.getVoyage(),
                this.fclService.getFcl(),
                this.seaportService.getSeaport()
            ]).then(
                (data) => {
                    data[0].map(item => {
                        item.vessel = data[1].find(a =>a.id == item.vessel_id);
                        item.pol = data[4].find(a =>a.id == item.pol);
                        item.pod = data[4].find(a =>a.id == item.pod);
                    });
                    this.voyageList = data[0];
                    this.onVoyageListChanged.next(data[0]);
                    resolve();
                },
                reject
            );
        });
    }

    /**
     * Get voyageList
     *
     * @returns {Promise<any>}
     */

    getVoyageList(): Promise<any>
    {
        let httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              'Authorization': this.authService.accessToken
            })
        };
        return new Promise((resolve, reject) => {
            this._httpClient.get(this.authService.apiUrl + '/voyage-list', httpOptions)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }
    getVoyageListByFclId(id): Promise<any>{
        let httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              'Authorization': this.authService.accessToken
            })
        };
        return new Promise((resolve, reject) => {
            this._httpClient.get(this.authService.apiUrl + '/voyage-list/fcl/' + id, httpOptions)
                .subscribe((response: any[]) => {
                    console.log(response);
                    resolve(response);
                }, reject);
        });
    }

    createVoyageList(voyageList): Promise<any>
    {
        let httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              'Authorization': this.authService.accessToken
            })
        };
        return new Promise((resolve, reject) => {
            this._httpClient.post(this.authService.apiUrl + '/voyage-list', voyageList, httpOptions)
                .subscribe((response: any) => {
                    this.dataCreate = response;
                    
                    resolve(response);
                }, reject);
        });
    }
    updateVoyageList(voyageList): Promise<any>
    {
        let httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              'Authorization': this.authService.accessToken
            })
        };
        return new Promise((resolve, reject) => {
            this._httpClient.put(this.authService.apiUrl + '/voyage-list', voyageList, httpOptions)
                .subscribe((response: any) => {
                    this.dataUpdate = response;
                    
                    resolve(response);
                }, reject);
        });
    }

    deleteVoyageList(id): Promise<any>
    {
        let httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              'Authorization': this.authService.accessToken
            })
        };
        return new Promise((resolve, reject) => {
            this._httpClient.delete(this.authService.apiUrl + '/voyage-list/' + id, httpOptions)
                .subscribe((response: any) => {
                    
                    this.dataDelete = response;
                    resolve(response);
                }, reject);
        });
    }

    deleteRow(voyageList): void
    {
        const voyageListIndex = this.voyageList.indexOf(voyageList);
        this.voyageList.splice(voyageListIndex, 1);
        this.onVoyageListChanged.next(this.voyageList);
    }
}
