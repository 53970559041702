import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, throwError } from 'rxjs';

import { Http, Response, Headers, URLSearchParams, RequestOptions} from '@angular/http';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { map, take, catchError} from 'rxjs/operators';
import { AuthService } from 'app/auth.service';

@Injectable()
export class SelVendorService implements Resolve<any>
{
    id;
    supplierId;
    supplierInfo;
    selVendor: any[];
    selVendorDetails: any[];
    supplier: any[];
    users: any[];
    price: any[];
    units: any[];
    currency: any[];
    country: any[];
    onSelVendorChanged: BehaviorSubject<any>;
    onDataChanged: BehaviorSubject<any>;
    
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        private _http: Http,
        private authService: AuthService,
        private router: Router
    )
    {
        // Set the defaults
        this.onSelVendorChanged = new BehaviorSubject([]);
        this.onDataChanged = new BehaviorSubject({});
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        this.id = route.params.id;
        this.supplierId = route.params.supplierId;
        return new Promise((resolve, reject) => {

            Promise.all([
                this.id ? this.getDetailsSelVendor(this.id) : (this.supplierId ? this.getSelVendorBySupplier(this.supplierId) : this.getSelVendor()),
                this.getSelVendorData()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    /**
     * Get selVendor
     *
     * @returns {Promise<any>}
     */

    getSelVendor(): Promise<any>
    {
        let httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              'Authorization': this.authService.accessToken
            })
        };
        return new Promise((resolve, reject) => {
            this._httpClient.get(this.authService.apiUrl + '/partner/sel-vendor', httpOptions)
                .subscribe((response: any) => {
                    this.selVendor = response;
                    this.onSelVendorChanged.next(response);
                    resolve(response);
                }, reject);
        });
    }
    getSelVendorBySupplier(supplierId): Promise<any>
    {
        let httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              'Authorization': this.authService.accessToken
            })
        };
        return new Promise((resolve, reject) => {
            this._httpClient.get(this.authService.apiUrl + '/partner/sel-vendor/supplier/' + supplierId, httpOptions)
                .subscribe((response: any) => {
                    this.supplierInfo = response.supplierInfo;
                    this.selVendor = response.selVendor;
                    this.onSelVendorChanged.next(response);
                    resolve(response);
                }, reject);
        });
    }
    getSelVendorData(): Promise<any>
    {
        let httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              'Authorization': this.authService.accessToken
            })
        };
        return new Promise((resolve, reject) => {
            this._httpClient.get(this.authService.apiUrl + '/partner/sel-vendor/data', httpOptions)
                .subscribe((response: any) => {
                    this.supplier = response.supplier;
                    this.users = response.users;
                    this.price = response.price;
                    this.units = response.units;
                    this.currency = response.currency;
                    this.country = response.country;
                    this.onDataChanged.next(response);
                    resolve(response);
                }, reject);
        });
    }
    getDetailsSelVendor(id): Promise<any>
    {
        let httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              'Authorization': this.authService.accessToken
            })
        };
        return new Promise((resolve, reject) => {
            this._httpClient.get(this.authService.apiUrl + '/partner/sel-vendor/id/' + id, httpOptions)
                .subscribe((response: any) => {
                    this.selVendor = response.vendor;
                    this.selVendorDetails = response.vendor_details;
                    this.onSelVendorChanged.next(response);
                    resolve(response);
                }, reject);
        });
    }
    createSelVendor(selVendor): Promise<any>
    {
        let httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              'Authorization': this.authService.accessToken
            })
        };
        return new Promise((resolve, reject) => {
            this._httpClient.post(this.authService.apiUrl + '/partner/sel-vendor', selVendor, httpOptions)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }
    updateSelVendor(selVendor): Promise<any>
    {
        let httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              'Authorization': this.authService.accessToken
            })
        };
        
        return new Promise((resolve, reject) => {
            this._httpClient.put(this.authService.apiUrl + '/partner/sel-vendor', selVendor, httpOptions)
                .subscribe((response: any) => {
                    
                    resolve(response);
                }, reject);
        });
    }

    deleteSelVendor(id): Promise<any>
    {
        let httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json',
              'Authorization': this.authService.accessToken
            })
        };
        return new Promise((resolve, reject) => {
            this._httpClient.delete(this.authService.apiUrl + '/partner/sel-vendor/' + id, httpOptions)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    deleteRow(selVendor): void
    {
        const selVendorIndex = this.selVendor.indexOf(selVendor);
        this.selVendor.splice(selVendorIndex, 1);
        this.onSelVendorChanged.next(this.selVendor);
    }
}
